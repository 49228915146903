<template>
  <b-modal
      id="payment-gateway-modal"
      hide-footer
      title="ลงทะเบียน Payment Gateway"
  >
    <b-overlay :show="isFetching">
      <ValidationObserver v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(onSubmit)">
          <ValidationProvider
              v-slot="{ errors }"
              name="apiKey"
              rules="required"
          >
            <b-form-group label="Secret Key">
              <b-form-input
                  v-model="form.apiKey"
                  :aria-invalid="false"
                  :state="errors[0] ? false : null"
                  name="apiKey"
                  type="text"
              ></b-form-input>
              <b-form-invalid-feedback>
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </ValidationProvider>

          <div class="text-right">
            <b-button :disabled="isFetching" type="submit" variant="success">
              ลงทะเบียน
            </b-button>
          </div>
        </form>
      </ValidationObserver>
    </b-overlay>
  </b-modal>
</template>

<script>
import Vue from 'vue';
import {mapActions, mapGetters} from 'vuex';

export default Vue.extend({
  name: 'AutoPaymentGatewayModal',
  props: {
    agentBankAccountId: {
      type: String,
      required: true
    },
    paymentGateway: {
      type: Object,
      default: () => ({
        apiKey: '',
      })
    }
  },
  data() {
    return {
      isFetching: false,
      form: {
        apiKey: '',
      }
    }
  },
  computed: {
    ...mapGetters(['userInfo']),
  },
  created() {
    this.form = {
      apiKey: this.paymentGateway?.apiKey || '',
    }
  },
  methods: {
    ...mapActions(['registerPaymentGateway', 'fetchBankAccount']),
    async onSubmit() {
      this.isFetching = true
      const response = await this.registerSMS({ id: this.agentBankAccountId, data: this.form })
      if(response) {
        this.$bvModal.hide('payment-gateway-modal')
        await this.fetchBankAccount(this.agentBankAccountId)
      }
      this.isFetching = false
    },
  }
})
</script>

<style scoped>

</style>